<template>
  <div>
    <!-- 外汇-课程交易-成交详情 -->
    <div style="margin-top:20px">币种类型：
      <el-select v-model="accountType" @change="accountHistory" clearable placeholder="请选择币种类型！" style="width: 280px;" size="mini">
        <el-option v-for="item in currencyData" :key="item.id" :label="item.name" :value="item.abbr"/> 
      </el-select>

      <!-- 查询历史 -->
      <span style="padding-left: 50px;"> 查询历史 &nbsp
        <el-tooltip class="item" effect="dark" content="默认查询当日" placement="top">
          <i style="color: #faad14" class="el-icon-warning"></i>
        </el-tooltip>&nbsp
      </span>
      <el-date-picker size="mini" v-model="historicalTime" type="daterange" range-separator="至" start-placeholder="开始日期" value-format="YYYY-MM-DD HH:mm:ss"
        end-placeholder="结束日期" @change="accountHistory">
      </el-date-picker>
      <el-dropdown size="mini" style="margin-left: 10px" split-button type="primary">
        <span>导出历史</span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>导出全部历史</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <!--成交详情表 -->
    <div style="margin-top:20px" v-loading="loading">
      <el-table :data="forexOrderVOList" stripe style="width: 100%"
        :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
        <el-table-column label="交易时间" prop="createTime" width="230" />
        <el-table-column label="买入币种" prop="buyerCurrency" />
        <el-table-column label="卖出币种" prop="sellerCurrency" />
        <el-table-column label="卖出金额" prop="purchasingAmounts" />
        <el-table-column label="交易汇率" prop="exchangeRate" />
        <el-table-column label="订单状态" prop="orderState" />
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="demo-pagination-block">
      <el-pagination 
        :current-page="currentPage" 
        :page-size="pageSize"
        :page-sizes="[5, 10, 15, 20]" 
        layout="total, sizes, prev, pager, next, jumper" 
        :total="total" 
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>


  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue';
import { courseAccount, findCurrency,accountHistorys } from './CourseTransactionDetailsMethods'
export default {
  setup() {
    onMounted(() => {
      courseAccount(data)
      findCurrency(data)
    })
    let data = reactive({
      loading:true,
      historicalTime: ['',''],
      accountType: '',
      currentPage: 1,//当前页面为1
      pageSize: 10,//每页业条数
      total:0 ,//条目总数
      forexOrderVOList: [],//交易订单
      currencyData: [],//货币
    })

    let handleSizeChange=(val)=>{
      data.pageSize = val
      courseAccount(data)
    }
    let handleCurrentChange=(val)=>{
      data.currentPage = val
      courseAccount(data)
    }
    let accountHistory = () => {
      console.log("aa---");
      accountHistorys(data)
    }
    return {
      ...toRefs(data),
      handleSizeChange,
      handleCurrentChange,
      accountHistory
    }
  },
}
</script>
<style lang="less" scoped>
.demo-pagination-block{
  margin-top: 20px;
  float: right
}
</style>